
html,
body,
#__next {
  	//width: 100%;
  	//height: 100%;
	min-height: 100%;
}

body, b, i, h1, h2, h3, h4, h5, h6, p, tr, th, td, li, textarea, select, input, span:not([class^='material-icons']) {
	font-family: 'Inter', sans-serif !important;
	line-height: 25px;
}

#__next .MuiContainer-maxWidthMd {
	max-width: 1400px;
}

.black-menu-item {
	a {
		color: black !important;
	}
}

a {
	color: #1068e5;
	text-decoration: none;
	font-size: 15px;

	&:hover {
		text-decoration: underline;;
	}
}


footer {
	background: #1f5798 !important;
	color: white;

	a {
		color: white;
		text-decoration: underline;
	}
}

// Custom scroll styles
*::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background: rgba(161, 161, 161, 0.18);
}
*::-webkit-scrollbar-thumb {
	background-color: #a1a1a1;
	border-radius: 5px;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.fill img {
    // flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
}

#__next h5 {
	font-size: 30px;
}

#__next h6 {
	margin-bottom: 10px;
}

.provisioning-cluster .MuiTabs-root button, {
	color: #242424;
	font-weight: 500;
}
.provisioning-cluster b,
.provisioning-cluster button,
.provisioning-cluster table th,
.provisioning-cluster h6 {
	font-weight: 500;
}
.provisioning-cluster * {
	font-weight: 300;
}

@import "axonUI/axon";
@import "elements/materialUI";
@import "elements/chat";
@import "elements/terminal";
@import "elements/stepper";
@import "elements/sideMenu";
@import "elements/quillTextEditor";
@import "pages/support.scss";

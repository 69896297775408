#__next,
div {
	// use material ui props instead (size="small")
	// .MuiOutlinedInput-input {
	// 	padding: 14px 14px;
	// }

	.\:block {
		display: block !important;
	}

	.\:pointer {
		cursor: pointer !important;
	}

	.\:disabled {
		pointer-events: none;
		opacity: 0.5;
		filter: grayscale(1);
	}

	.\:noselect {
		user-select: none;
	}

	.\:w100 {
		width: 100%;
	}

	.\:h100 {
		height: 100%;
	}

	.\:inline {
		display: inline-block !important;
	}

	.\:relative {
		position: relative;
	}

	.\:fixed {
		position: fixed;
	}

	.\:absolute {
		position: absolute;
	}

	.\:nowrap {
		white-space: nowrap;
	}

	@at-root .\:clickable {
		cursor: pointer;
		transform: translate(0, 0);

		&:hover {
			opacity: 0.8;
		}

		&:active {
			transform: translate(1px, -1px);
		}
	}

	.\:clickable:disabled {
		cursor: auto;

		&:hover {
			opacity: 1;
		}
	}

	.\:overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 1;
		left: 0;
		background: rgba(0, 0, 0, 0.5);
	}

	.\:overlay0 {
		@extend .\:overlay;
		background: transparent;
	}
}

$fontsSizes: 9,10,11,12,13,14,15,16,17,18,19,20,22;

#__next,
div {
	.\:h1 {
		font-size: 40px;
		font-weight: bold;
		margin: 0;
	}

	.\:h2 {
		font-size: 30px;
		font-weight: 500;
		margin: 0;
	}

	.\:h3 {
		font-size: 25px;
		font-weight: 500;
		margin: 0;
	}

	.\:h4 {
		font-size: 20px;
		font-weight: 500;
		margin: 0;
	}

	.\:h5 {
		font-size: 18px;
		font-weight: 500;
		margin: 0 0 map-get($axon, baseMargin) 0;
	}

	.\:h6 {
		font-size: 16px;
		font-weight: 500;
		margin: 0;
	}

	.\:fs\:small {
		font-size: 12px;
		line-height: 120%;
	}

	.\:fs\:mid,
	.\:fs\:middle {
		font-size: 13px;
		line-height: 120%;
	}

	.\:fs\:big {
		font-size: 18px;
	}

	.\:fw200 {
		font-weight: 200 !important;
	}

	.\:fw300 {
		font-weight: 300 !important;
	}

	.\:fw400 {
		font-weight: 400 !important;
	}

	.\:fw500 {
		font-weight: 500 !important;
	}

	@each $size in $fontsSizes {
		.\:fs#{$size} {
			font-size: #{$size}px;
		}
	}
}

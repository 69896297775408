#__next,
div {

	.\:text\:orange {
		color: orange;
	}

	.\:text\:gray {
		color: gray;
	}

	.\:text\:blue {
		color: #1068e5;
	}

	.\:text\:red {
		color: red;
	}

	.\:text\:green {
		color: #2e7d32;
	}

	.\:text\:black {
		color: black;
	}

	.\:text\:gray {
		color: #797979;
	}

	.\:text\:underline {
		text-decoration: underline;
	}

	.\:text\:align\:left {
		text-align: left;
	}

	.\:text\:align\:center {
		text-align: center;
	}

	.\:text\:capitalize {
		text-transform: capitalize;
	}

	.\:text\:black {
		color: black;
	}
}

$sideMenuWidth: 50px;
$sideMenuOpenWidth: 270px;
$animationDuration: 0.4s;

#__next {

	.\:sideMenu-wrapper {
		width: 100%;
		background: #efeff0;
		position: relative;	
		flex: 1;
	}

	.\:sideMenu {
		color: white;
		position: absolute;
		background: #0e5b89;
		border-right: 1px solid #e2e1e1;
		padding-top: 10px;
		top: 40px;
		bottom: 0;
		width: $sideMenuOpenWidth;
		transition: transform $animationDuration ease;
		transform: translateX(- ($sideMenuOpenWidth - $sideMenuWidth));
		z-index: 1;
		user-select: none;
		overflow-y: auto;
		overflow-x: hidden;

		.level1 {
			i, p, div {
				font-size: 13px;
				font-weight: 600;
			}
		}

		.level2,
		.level3 {
			i, p, div {
				font-size: 13px;
				font-weight: 400;		
			}
		}
	}

	// .\:sideMenu-button {
	// 	position: absolute;
	// 	top: 10px;
	// 	right: -26px;
	// 	z-index: 99999;
	// 	border-radius: 50%;

	// 	width: 35px;
	// 	height: 35px;
	// 	background: #002c47;
	// 	color: white;

	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	cursor: pointer;
	// }

	.\:sideMenu-button {
		position: absolute;
		top: 85px;
		left: 36px;
		z-index: 2;
		background-color: #4e7d99;
		color: white;
		transition: all 0.4s ease;
		height: 24px;
    	width: 24px;

		&:hover {
			background-color: #528bc1;
		}
	}


	.\:sideMenu-content {
		will-change: transform;
		transform: translateZ(0);
		margin-left: $sideMenuWidth;
		transition: margin-left $animationDuration ease;
		height: 100%;
		padding: 0 10px;
	}


	.\:sideMenu-icon {
		display: flex;
		will-change: transform;
		transform: translateX(225px);
	}


	.\:sideMenu-text {
		transition: opacity $animationDuration ease;
		opacity: 0;
		font-weight: 500;
	}


	// Open menu state
	.\:sideMenu-wrapper--open {

		.\:sideMenu {
			transform: translateX(0);
		}

		.\:sideMenu-content {
			margin-left: $sideMenuOpenWidth;
		}

		.\:sideMenu-icon {
			transform: translateX(1px);
		}

		.\:sideMenu-text {
			display: flex;
			align-items: center;
			opacity: 1;
		}

		.\:sideMenu-button {
			left: 258px;
		}
	}

	.sideMenu__item {
		visibility: visible !important;
		opacity: 1 !important;
		position: relative;
		z-index: 10000;

		&:hover {
		}

		&--active {
			box-shadow: inset 2px 2px 3px 0 #082f44;
			//background: #efeff0;
			background: #0d4c71;
		}
		// &--bottom {
		// 	margin-top: auto;
		// }
	}
}

// 					<div className=':grid :grid:spacing10'>
//						<div className=':grid:xs12 :grid:sm1'>
//							<div style={{ background: 'orange' }}>OK</div>
//						</div>
//						<div className=':grid:xs6 :grid:sm3'>
//							<div style={{ background: 'gray' }}>oh</div>
//						</div>
//					</div>

@use "sass:math";

$gridSizes: 1,2,3,4,5,6,7,8,9,10,11,12;
$spacing: 1,2,3,4,5,6,7,8,9,10,11,12,13,14;

$grid: (
	xs: 599px,
	sm: 959px,
	md: 1279px,
	lg: 1919px
);


#__next,
div {
	%grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		//border-bottom: map-get($axon, baseMargin) solid transparent;
		margin-right: - map-get($axon, baseMargin);

		> div[class*="grid"] {
			border-right: map-get($axon, baseMargin) solid transparent;
			//border-bottom: map-get($axon, baseMargin) solid transparent;
		}

		> div[class*="grid"]:first-child {
			//border-top: none;
		}
	}


	.\:grid {
		@extend %grid;
	}

	.\:grid\:mb {
		@extend %grid;
		> div[class*="grid"] {
			border-right: map-get($axon, baseMargin) solid transparent;
			border-bottom: map-get($axon, baseMargin) solid transparent;
		}
	}


	// Default width of grid item
	@each $size in $gridSizes {
		.\:grid\:xs#{$size} {
			box-sizing: border-box;
			width: math.div($size * 100%, 12);
		}
	}
	@each $size in $gridSizes {
		.\:grid\:sm#{$size} {
			box-sizing: border-box;
			width: math.div($size * 100%, 12);
		}
	}
	@each $size in $gridSizes {
		.\:grid\:md#{$size} {
			box-sizing: border-box;
			width: math.div($size * 100%, 12);
		}
	}
	@each $size in $gridSizes {
		.\:grid\:lg#{$size} {
			box-sizing: border-box;
			width: math.div($size * 100%, 12);
		}
	}


	// Spacing
	@each $size in $spacing {
		.\:grid\:spacing#{$size} {
			border-bottom: #{$size}px solid transparent;
			margin-right: -#{$size}px;
			margin-top: -#{$size}px;

			> div[class*="grid"] {
				border-right: #{$size}px solid transparent;
				border-top: #{$size}px solid transparent;
			}
		}
	}

	// xs(0 - 599px)
	@media (min-width: 0) and (max-width: map-get($grid, xs)) {
		@each $size in $gridSizes {
			.\:grid\:xs#{$size} {
				width: math.div($size * 100%, 12);
			}
		}
	}

	// sm(600px - 959px)
	@media (min-width: map-get($grid, xs)+1) and (max-width: map-get($grid, sm)) {
		@each $size in $gridSizes {
			.\:grid\:sm#{$size} {
				width: math.div($size * 100%, 12);
			}
		}
	}

	// md(960px - 1279px)
	@media (min-width: map-get($grid, sm)+1) and (max-width: map-get($grid, md)) {
		@each $size in $gridSizes {
			.\:grid\:md#{$size} {
				width: math.div($size * 100%, 12);
			}
		}
	}

	// lg(1280px - 1919px)
	@media (min-width: map-get($grid, md)+1) and (max-width: map-get($grid, lg)) {
		@each $size in $gridSizes {
			.\:grid\:lg#{$size} {
				width: math.div($size * 100%, 12);
			}
		}
	}

	// xl(1920px - Infinity)
	@media (min-width: map-get($grid, lg)+1) {
		@each $size in $gridSizes {
			.\:grid\:xl#{$size} {
				width: math.div($size * 100%, 12);
			}
		}
	}
}
#__next,
div {

	.\:flex {
		display: flex;
	}

	.\:flex\:wrap {
		display: flex;
		flex-wrap: wrap;
	}

	.\:flex\:center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.\:flex\:mid {
		display: flex;
		align-items: center;
	}

	.\:flex\:right {
		display: flex;
		justify-content: flex-end;
	}

	.\:flex\:left {
		display: flex;
		justify-content: flex-start;
	}

	.\:flex\:left\:center,
	.\:flex\:center\:left {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.\:flex\:between\:center,
	.\:flex\:center\:between
	{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.\:flex\:between {
		display: flex;
		justify-content: space-between;
	}

	.\:flex\:column {
		display: flex;
		flex-direction: column;
	}

	.\:flex\:top {
		display: flex;
		align-items: flex-start;
	}

	.\:flex\:bottom {
		display: flex;
		align-items: flex-end;
	}

	.\:flex1 {
		display: flex;
		flex: 1;
	}
	.\:flex\-inline\:center {
		display: inline-flex;
		vertical-align: middle;
		align-items: center;
	}
}

#__next {
  .\:terminal {
    font-family: Roboto Mono, SFMono-Regular, Consolas, Menlo, monospace;
    box-shadow: none;
    display: block;
    margin: 0;
    position: relative;
    overflow: auto;
    padding: 10px 15px;
    background: whitesmoke;
    color: #394951;
    font-size: 13px;
    border-radius: 4px;
    touch-action: auto;
    word-break: normal;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #adadad;
    }

    &::-webkit-scrollbar:vertical {
      display: none;
    }

    pre {
      display: block;
      font-family: Roboto Mono, SFMono-Regular, Consolas, Menlo, monospace;
      font-size: 13px;
    }

    &-copy {
      position: absolute;
      right: 5px;
      color: black;
      top: 5px;

      .MuiSvgIcon-root {
        width: 20px;
        opacity: 0.2;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}

#__next,
div {

	%button {
		box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
		color: white;

		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
			pointer-events: none;
		}

		&:active {
			transform: scale(.97);
		}

		&:hover {
			opacity: 0.8;
		}
	}

	.\:button {
		@extend %button;
	}

	.\:button\:error {
		@extend %button;
		background: #f44336;
	}

	.\:button\:warning {
		@extend %button;
		background: #fe9800;
	}

	.\:button\:info {
		@extend %button;
		background: #1f5798;
	}

	.\:button\:success {
		@extend %button;
		background: #4caf50;
	}
}

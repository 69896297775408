$sizes: 0, 2, 3, 5, 7, 8, 10, 15, 16, 20, 24, 25, 30, 40, 45, 50, 120, 200, 250, 300, 350, 400;

$axon: (
	baseMargin: 15px
);


@import "./basic";
@import "./flex";
@import "./button";
@import "./text";
@import "./card";
@import "./grid";
@import "./typography";


#__next,
div {
	button, a {
		touch-action: manipulation; // ~300 ms faster clicks
	}

	.\:input {
		border: none;
		outline: none;
		line-height: 22px;
		padding: 0 5px;
		width: 100%;
	}

	@each $size in $sizes {
		.\:p#{$size} {
			padding: #{$size}px;
		}
		.\:pr#{$size} {
			padding-right: #{$size}px;
		}
		.\:pl#{$size} {
			padding-left: #{$size}px;
		}
		.\:pt#{$size} {
			padding-top: #{$size}px;
		}
		.\:pb#{$size} {
			padding-bottom: #{$size}px;
		}

		.\:m#{$size} {
			margin: #{$size}px;
		}
		.\:mr#{$size} {
			margin-right: #{$size}px;
		}
		.\:ml#{$size} {
			margin-left: #{$size}px;
		}
		.\:mt#{$size} {
			margin-top: #{$size}px;
		}
		.\:mb#{$size} {
			margin-bottom: #{$size}px;
		}

		.\:br#{$size} {
			border-radius: #{$size}px;
		}

		.\:minh#{$size} {
			min-height: #{$size}px;
		}

		.\:minw#{$size} {
			min-width: #{$size}px;
		}
	}
}

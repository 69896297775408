.theme-light {
	#__root {
		.\:card {
			box-shadow: 1px 1px 3px 1px rgb(210, 210, 210);
		}
	}
}

#__next,
div {
	.\:card, .MuiPaper-elevation2 {
		box-shadow: 2px 2px 6px 2px #d2d2d2;
		border-radius: 4px;
		background: white;
		padding: 16px;
	}

	.\:card\:header {
		margin: 15px 0;
		display: flex;
		text-transform: capitalize;
		flex-wrap: wrap;
		align-items: center;
		font-size: 20px;
		font-weight: 600;
		letter-spacing: -0.4px;
	}

	.\:card\:subheader {
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		color: gray;
	}

	.\:card\:avatar {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;

		i {

			font-size: 20px !important;
		}

		svg {
			font-size: 23px;
		}
	}
}

#__next {

	.\:chat {
		position: relative;

		.\:chat-posts {
			max-height: 67vh;
			min-height: 200px;
			overflow-y: auto;
			padding-right: 10px;

			&::-webkit-scrollbar {
				width: 9px; // TODO: Fix for horizontal scroll
			}
			&::-webkit-scrollbar-track-piece  {
				background: #ececec;
			}
			&::-webkit-scrollbar-thumb:vertical {
				background-color: darkgray;
				border-radius: 5px;
				cursor: pointer;
			}
		}

		.\:chat-post {
			display: flex;
			align-items: flex-start;
			flex-direction: column;

			.\:chat-post-body {
				padding: 8px 18px 16px;
				margin: 20px 0 5px 25px;
				border-radius: 15px 15px 15px 0;
				box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
				background: #0063cc;
				color: white;
				max-width: 85%;
				min-width: 150px;
				overflow: hidden;
				overflow-wrap: break-word;

				&--customer a {
					color: white;
					text-decoration: underline;
				}
			}

			.\:chat-post-date {
				color: #d2d2d2;
				font-size: 12px;
				text-align: right;
				position: relative;
				top: 14px;
			}

			&--reply {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				justify-content: flex-end;

				.\:chat-post-body {
					border-radius: 15px 15px 0 15px;
					margin: 20px 5px 30px;
					background: #efefef;
					color: black;
				}

				.\:chat-post-date {
					color: gray;
				}
			}
		}


		.\:chat-actions {
			margin-top: 20px;
			padding: 16px;
			position: relative;
			left: -15px;
			min-height: 54px;
			width: calc(100% + 30px);
			border-top: 1px solid gainsboro;

			> div {
				width: 50px;
			}

			.\:chat-btn {
				color: #0063cc;
			}

			textarea {
				width: 100%;
				border: none;
				resize: none;
				outline: none;
				border-radius: 5px;
				padding: 10px;

				&::placeholder {
					color: gainsboro;
				}

				&::-webkit-scrollbar {
					width: 9px; // TODO: Fix for horizontal scroll
				}
				&::-webkit-scrollbar-track-piece  {
					background: #ececec;
				}
				&::-webkit-scrollbar-thumb:vertical {
					background-color: darkgray;
					border-radius: 5px;
					cursor: pointer;
				}
			}
		}
	}
	
	.\:chat-post .code-wrapper {
		background: #f4f5f7;
		padding: 8px;
		font-size: 14px;
		color: #172B4D;
		line-height: 24px;
		display: block;
		margin: 0;
		overflow-x: auto;
		border-radius: 5px;
	}
	
	.\:chat-post p {
		margin: 0;
	}

	.\:chat-post__wrap code {
		white-space: pre;
	}

	.\:chat-post__no-wrap code {
		white-space: normal;
	}

	.\:upload-file {
		&:hover {
			background-color: rgba(25, 118, 210, 0.04);
		}
	}

	.chat-and-details-container {
		flex-direction: row;
	}
	
	@media only screen and (max-width: 1279px) {
		.chat-and-details-container {
			flex-direction: column-reverse;
		}
	}

	.ticket-post-button__copy {
		position: absolute;
		top: 5px;
		right: 0px;
		background: #f4f5f7;
		border-radius: 5px;
	}

	.ticket-post-button__wrap {
		position: absolute;
		top: 5px;
		right: 34px;
		background: #f4f5f7;
		border-radius: 5px;
	}
}

#__next {
  code {
    background: rgb(245, 245, 245);
    padding: 0 4px;
    margin: 0 2px;
    border-radius: 2px;
  }

  .stepper-note {
      border-left: 4px solid rgba(0, 0, 0, 0.32);
      margin: 15px;
      padding-left: 12px;
  }

  .stepper-square {
    padding: 12px;
    box-shadow: 1px 1px 3px 1px rgb(210, 210, 210);
    width: 170px;
    height: 170px;
    border-radius: 5px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 20px 0 0;

    .stepper-square-img {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;

      img {
        max-height:100%;
        max-width:100%;
        object-fit: contain;
      }
    }

    .stepper-square-text {
      margin-top: 15px;
    }
  }

  pre {
    display: inline;
    margin: 0;
  }
}

.ql-snow .ql-editor code {
	display: block;
	font-size: 13px;
}

.ql-wrapper-create-ticket-page .ql-editor {
	min-height: 300px;
}

.ql-wrapper-active-drag .ql-editor {
	background-color: rgba(17, 109, 210, 0.5);
	background-image: url('../../public/images/dragndrop-bg.png');
	background-repeat: no-repeat;
	background-position: center;
    background-attachment: fixed;
}

// .ql-wrapper-active-drag .ql-editor code {
// 	background-color: inherit;
// }

.ql-wrapper-support-post-page .ql-editor {
	min-height: 80px;
}